<template>
    <div class="blocks-wrap">
        <ul class="source-type-wrap">
            <li v-if="spaceType==='company'" :class="['source-type-item', 'pointer', sourceType==='company'?'source-type-item-active':'']" data-name="company" @click="handleSourceType">{{ $t('navigation.companySpaces') }}</li>
            <li v-if="spaceType==='person'" :class="['source-type-item', 'pointer', sourceType==='person'?'source-type-item-active':'']" data-name="person" @click="handleSourceType">{{ $t('navigation.personSpaces') }}
            </li>
            <li :class="['source-type-item', 'pointer', sourceType==='particular'?'source-type-item-active':'']" data-name="particular" @click="handleSourceType">{{ $t('pages.template.otherMaterials') }}</li>
        </ul>
        <div class="components-wrap">
            <person-images
                v-if="sourceType==='person'"
                :formats="formats"
                @handleAddImage="handleAddImage"
            ></person-images>
            <company-images
                v-if="sourceType==='company'"
                :is_org="is_org"
                :formats="formats"
                @handleAddImage="handleAddImage"
                @handleDisableKeyboard="handleDisableKeyboard"
            ></company-images>
            <particular-images
                v-if="sourceType==='particular'"
                @handleAddImage="handleAddImage"
                @handleDisableKeyboard="handleDisableKeyboard"
            ></particular-images>
        </div>
    </div>
</template>
<script>
    import personImages from './personImages'
    import companyImages from './companyImages'
    import particularImages from './particularImages'

    export default {
        name: "",
        mixins: [],
        components: {
            personImages,
            companyImages,
            particularImages
        },
        props: {
            canvasWidth: {
                default: 1920
            },
            canvasHeight: {
                default: 1080
            },
            is_org: {
                default: ''
            },
            minLimit: {
                default: 0
            },
            spaceType: {
                default: ''
            }
        },
        data () {
            return {
                isLabelLoaded: false,
                isLoaded: false,
                current: 1,
                pageSize: 12,
                total: 0,
                list: [],
                query: '',
                loading: false,
                label: null,
                labelList: [],
                labelCurrent: 1,
                labelPageSize: 10,
                uploadModalShow: false,
                sourceType: '',
                formats: ['ts', 'vob', 'webm', 'swf', 'mj2', 'mjpeg', 'mpe', 'm3u8', 'm4v', 'm2t', 'f4v', 'dv','rm', 'rmvb', '3gp', 'avi', 'mpeg', 'mpg', 'mkv', 'dat', 'asf', 'wmv', 'flv', 'mov', 'mp4', 'ogg', 'jpg', 'png', 'jpeg', 'mjpeg', 'bmp']
            }
        },
        computed: {
            bucket_token() {
                return this.$store.state.default_bucket_tokens.user
            },
            tree_data() {
                return [this.$store.state.up_tree]
            }
        },
        watch: {
            spaceType: {
                handler: function(val) {
                    this.sourceType = val
                },
                immediate: true
            }
        },
        methods: {
            handleSourceType(e) {
                let name = e.currentTarget.dataset.name
                if (name === this.sourceType) return
                this.sourceType = name
            },
            handleAddImage(item) {
                let params =  {
                    type: "image",
                    text: this.$t('pages.space.image'),
                    width: item.material_info.resolution[0],
                    height: item.material_info.resolution[1],
                    url: item.uri,
                    opacity: 1,
                    rotate: 0,
                    material_id: item.rowid
                }
                if (item.material_info.media_type == 'video') {
                    params.type = 'video'
                    params.text = this.$t('pages.space.video')
                    params.volume = 1
                }
                if (params.type === 'image' || params.type === 'video') {
                    if (params.type === 'video') {
                        params.width = this.canvasWidth
                        params.height = this.canvasHeight
                    }
                    if (params.type === 'image') {
                        /*
                         *长宽任一分辨率大于显示区域长宽时，最大长/宽缩小至显示区域长/宽的一半，居中显示
                         *长宽均大于显示区域长宽时，最大长/宽缩小至与显示区域值相应长/宽的一半，居中显示
                        **/
                        let scale = 1
                        if (params.width > this.canvasWidth) {
                            scale = params.width / this.canvasWidth
                            params.width = parseInt(params.width / scale)
                            params.height = parseInt(params.height / scale)
                        }
                        if (params.height > this.canvasHeight) {
                            scale = params.height / this.canvasHeight
                            params.width = parseInt(params.width / scale)
                            params.height = parseInt(params.height / scale)
                        }
                        params.width /= 2
                        params.height /= 2
                        if (params.width < params.height) {
                            if (params.width / this.canvasWidth < 0.2 || params.width / params.height < 0.2) {
                                scale = Math.min(this.canvasWidth, this.canvasHeight) * 0.2 / params.height
                                params.width *= scale
                                params.height *= scale
                            }
                        } else if (params.width > params.height) {
                            if (params.height / this.canvasHeight < 0.2) {
                                scale = Math.min(this.canvasWidth, this.canvasHeight) * 0.2 / params.width
                                params.width *= scale
                                params.height *= scale
                            }
                        }
                        if (params.width < this.minLimit) {
                            scale = this.minLimit / params.width
                            params.width *= scale
                            params.height *= scale
                        }
                        if (params.height < this.minLimit) {
                            scale = this.minLimit / params.height
                            params.width *= scale
                            params.height *= scale
                        }
                        // params.resolution = item.material_info.resolution
                        params.inner = {
                            x: 0,
                            y: 0,
                            width: params.width,
                            height: params.height
                        }

                    }
                }
                params.x = this.canvasWidth / 2 - params.width / 2
                params.y = this.canvasHeight / 2 - params.height / 2
                this.$emit('addImage', params)
            },
            handleDisableKeyboard(value) {
                this.$emit('handleDisableKeyboard', value)
            }
        },
        mounted() {}
    }
</script>
<style scoped lang="less">
.blocks-wrap {
    overflow: hidden;
    padding-left: 16px;
    height: 100%;
}
.source-type-wrap {
    overflow: hidden;
    margin: 8px 0 17px;
    border: 1px solid #cccccc;
    width: calc(100% - 16px);
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    .source-type-item {
        display: inline-block;
        width: 50%;
        height: 100%;
        background: #fff;
        line-height: 40px;
    }
    .source-type-item-active {
        background: #f1f2f4;
    }
}
.components-wrap {
    height: calc(100% - 65px);
}
</style>