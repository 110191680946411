<template>
    <div>
        <edit-template
            ref="edit-template-component"
            :bucket_token="bucket_token"
            prefix="/cym/"
            :batchReplaceable="true"
            :palyable="palyable"
            :hideTipModal="hideTipModal"
            :hideAudio="false"
            :is_org="is_org"
            :state="state"
            :rowid="rowid"
            :data_type="data_type"
            :disableKeyboard="disableKeyboard"
            :audio="audio"
            :getData="getData"
            :rename="handleRename"
            :editSubmission="editSubmission"
            :addSubmission="addSubmission"
            :jump="goTo"
            :getFonts="getFonts"
            :getFields="getFields"
            :getBatchReplacingData="getBatchReplacingData"
            :fontSize="fontSize"
            @handleCommodityModal="handleCommodityModal"
            @handleEditCommodity="handleEditCommodity"
            @handleAudio="handleAudio"
            @handleTTS="handleTTS"
            @handleDisableKeyboard="handleDisableKeyboard"
            @handleImagesModal="handleImagesModal"
            @handlePlay="handlePlay"
            @handleCanvasSize="handleCanvasSize"
            @newPage="newPage">
            <templates-block
                slot="templates-block"
                :is_org="is_org"
                :data_type="data_type"
                :spaceType="spaceType"
                @confirm="handleReplaceTmpl"
                @handleDisableKeyboard="handleDisableKeyboard"></templates-block>
            <images-block
                slot="images-block"
                :is_org="is_org"
                :canvasWidth="canvasWidth"
                :canvasHeight="canvasHeight"
                :minLimit="minLimit"
                :spaceType="spaceType"
                @addImage="handleAddItem"
                @handleDisableKeyboard="handleDisableKeyboard"></images-block>
        </edit-template>
        <audio-modal
            :show="audioModalShow"
            :selectedAudio="audio"
            :is_org="is_org"
            @confirm="handleAudioConfirm"
            @cancel="handleAudioCancel"></audio-modal>
        <tts-modal
            :show="ttsModalShow"
            :ttsInfo="ttsInfo"
            @cancel="handleTtsCancel"
            @confirm="handleTtsConfirm"></tts-modal>
        <commodity-modal
            :show="commodityModalShow"
            :bucket_token="bucket_token"
            @cancel="handleCommodityCancel"
            @confirm="handleAddCommodity"></commodity-modal>
        <edit-commodity-modal
            :show="editCommodityModalShow"
            :bucket_token="bucket_token"
            :rowid="commodityRowid"
            state="edit"
            @cancel="handleEditCommodityCancel"
            @confirm="handleEditCommodityConfirm"></edit-commodity-modal>
        <images-modal
            :show="imagesModalShow"
            :is_org="is_org"
            :type="currentType"
            @confirm="handleImagesModalConfirm"
            @cancel="handleImagesModalCancel"></images-modal>
        <playSetting
            :show="playModalShow"
            :materialInfo="materialInfo"
            @confirm="handlePlayConfirm"
            @cancel="handlePlayCancel">
            <div class="material-block" slot="material" :slot-scope="{ materialInfo }">
                <div class="img-wrap">
                    <img :class="['image-center', 'template-preview-image-bkg']" :src="`${materialInfo.uri}/thumbnail?size=[295,-1]`">
                </div>
                <div class="info">
                    <p>{{ $t('pages.template.name') }}：{{ materialInfo.name }}</p>
                    <p>{{ $t('pages.space.resolutionRatio') }}：{{ materialInfo.resolution }}</p>
                </div>
            </div>
        </playSetting>
    </div>
</template>
<script>
    import templatesBlock from './components/templates'
    import imagesBlock from './components/images'
    import audioModal from 'views/screens/programs/components/audioModal'
    import ttsModal from 'views/screens/programs/components/ttsModal'
    import commodityModal from './components/commodityModal'
    import editCommodityModal from 'views/others/goods/components/commodityModal'
    import imagesModal from './components/imagesModal'
    import playSetting from 'views/components/playSetting'
    import { EditTemplate } from '@cym/base_lib'

    export default {
        name: "",
        mixins: [],
        components: {
            imagesBlock,
            templatesBlock,
            EditTemplate,
            audioModal,
            ttsModal,
            commodityModal,
            editCommodityModal,
            imagesModal,
            playSetting
        },
        directives: {},
        props: [],
        data () {
            return {
                state: 'add',
                rowid: null,
                loading: false,
                audioModalShow: false,
                audio: null,
                disableKeyboard: false,//有弹窗时禁用快捷键
                ttsModalShow: false,
                ttsInfo: null,
                commodityModalShow: false,
                commodityRowid: null,
                editCommodityModalShow: false,
                canvasWidth: 1920,
                canvasHeight: 1080,
                minLimit: 1,
                data_type: '',
                imagesModalShow: false,
                currentType: '',
                playModalShow: false,
                materialInfo: {},
                fontSize: 40,
                dist_bucket_token: '',
                hideTipModal: false,
                spaceType: '',
                palyable: true
            }
        },
        computed: {
            percent() {
                return parseInt(this.scale * 100)
            },
            bucket_token() {
                if (this.dist_bucket_token) return this.dist_bucket_token
                let org = this.$store.state.default_bucket_tokens.org
                if (org) return org
                return this.$store.state.default_bucket_tokens.user
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            }
        },
        methods: {
            goTo(params) {
                this.$router.push(params)
            },
            addSubmission(params) {
                return this.$api.post('templates', params).then(({ rowid }) => {
                    this.rowid = rowid
                    this.state = 'edit'
                    return { rowid: rowid, state: 'add' }
                })
            },
            editSubmission(params) {
                return this.$api.put(`templates/${this.rowid}`, params).then(({ rowid }) => {
                    return { rowid: rowid, state: 'edit' }
                })
            },
            getFonts() {
                return this.$api.get('templates/font_list')
            },
            handleRename(params) {
                this.$api.put(`materials/${this.rowid}`, params).then(() => {
                    this.$Message.success(this.$t('common.success'))
                })
            },
            getData(rowid) {
                return this.$api.get(`templates/${rowid}`).then((data) => {
                    this.canvasWidth = data.data.template.width
                    this.canvasHeight = data.data.template.height
                    this.handleFontSize(this.canvasWidth)
                    return data
                })
            },
            handleFontSize(width) {
                if (width < 200) this.fontSize = 10
                if (width >= 200 && width <= 800) this.fontSize = Math.floor(width / 20)
                if (width > 800) this.fontSize = 40
            },
            getFields(params) {
                return this.$api.get('commodities/fields', { params })
            },
            handleDisableKeyboard(value) {
                this.disableKeyboard = value
            },
            handleAudio(params) {
                this.audioModalShow = params.audioModalShow
                this.audio = params.audio
            },
            handleAudioConfirm(params) {
                this.audio = {
                    name: params.name,
                    material_id: params.rowid,
                    media_type: params.media_type,
                    url: params.uri
                }
                this.$refs['edit-template-component'].handleAudioConfirm()
            },
            handleAudioCancel() {
                this.audioModalShow = false
                this.disableKeyboard = false
            },
            handleTTS(params) {
                this.ttsModalShow = params.ttsModalShow
                this.ttsInfo = params.ttsInfo
            },
            handleTtsCancel() {
                this.ttsModalShow = false
                this.disableKeyboard = false
            },
            handleTtsConfirm(params) {
                this.ttsInfo = params
                this.$refs['edit-template-component'].handleTtsConfirm()
            },
            handleCommodityCancel() {
                this.commodityModalShow = false
                this.disableKeyboard = false
            },
            handleCommodityModal() {
                this.commodityModalShow = true
                this.disableKeyboard = true
            },
            handleAddCommodity(params) {
                this.$refs['edit-template-component'].handleAddCommodity(params)
            },
            handleEditCommodity(rowid) {
                this.commodityRowid = rowid
                this.editCommodityModalShow = true
                this.disableKeyboard = true
            },
            handleEditCommodityCancel() {
                this.editCommodityModalShow = false
                this.disableKeyboard = false
            },
            handleEditCommodityConfirm() {
                let params = {
                    bucket_token: this.bucket_token,
                    rowid: this.commodityRowid
                }
                this.$api.get('commodities/single_commodity', { params }).then(({ data={} }) => {
                    this.$refs['edit-template-component'].handleEditCommodityConfirm(data)
                    this.handleEditCommodityCancel()
                })
            },
            handleReplaceTmpl(params) {
                this.$refs['edit-template-component'].handleReplaceTmpl(params)
            },
            handleAddItem(params) {
                this.$refs['edit-template-component'].handleAddItem(params)
            },
            handleImagesModal(type) {
                this.currentType = type
                this.imagesModalShow = true
            },
            handleImagesModalCancel() {
                this.imagesModalShow = false
                this.disableKeyboard = false
            },
            handleImagesModalConfirm(item) {
                this.$refs['edit-template-component'].handleImagesModalConfirm(item)
            },
            handlePlay(params) {
                this.materialInfo = params
                this.playModalShow = true
            },
            handlePlayCancel() {
                this.playModalShow = false
                this.disableKeyboard = false
                this.$refs['edit-template-component'].handlePlayCancel()
            },
            handlePlayConfirm() {
                this.handlePlayCancel()
            },
            getBatchReplacingData(params) {
                return this.$api.get('commodities/search', { params })
            },
            handleCanvasSize(params) {
                this.canvasWidth = params.width
                this.canvasHeight = params.height
                this.handleFontSize(this.canvasWidth)
            },
            newPage(name) {
                const { href } = this.$router.resolve({
                    name: name
                });
                window.open(href, '_blank')

            }
        },
        beforeDestroy() {},
        async mounted() {
            let palyable = this.$route.query.palyable
            if (palyable === 'false' ) this.palyable = false
            this.state = this.$route.query.state
            this.rowid = this.$route.query.rowid
            this.data_type = this.$route.query.templateType==='template'?'static':'dynamic'
            this.dist_bucket_token = this.$route.query.dist_bucket_token
            this.spaceType = this.$route.query.spaceType?this.$route.query.spaceType:'company'
            if  (this.dist_bucket_token)  this.hideTipModal = true
        }
    }
</script>
<style scoped lang="less">
.material-block {
    margin-bottom: 20px;
    height: 166px;
    .img-wrap {
        display: inline-block;
        position: relative;
        margin-right: 20px;
        height: 165px;
        width: 296px;
        background: #cfcfcf;
    }
    .info {
        display: inline-block;
        vertical-align: bottom;
        font-weight: 700;
    }
}
</style>