<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.goods.selectData')"
        :width="1000"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <div class="search-bar clearfix">
            <Input v-model="query" class="float-right" clearable :placeholder="$t('pages.goods.inputToSearch')" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" @paste.native="handleStop" @cut.native="handleStop" @keydown.native="handleStop" @keyup.native="handleStop" >
                <Icon type="ios-search pointer" slot="suffix" @click="searchNew" />
            </Input>
        </div>
        <no-data v-show="list.length === 0" :text="$t('pages.goods.noData')"></no-data>
        <div class="content-section">
            <RadioGroup v-show="list.length > 0" v-model="commodity.rowid" class="commodity-block" >
                <div class="radio-wrap" v-for="item in list" :key="item.rowid" >
                    <div class="img-wrap-block">
                        <div class="img-wrap image-center">
                            <img class="image-center" :src="`/cym/${item.data.banner_picture?item.data.banner_picture.url:''}`">
                        </div>
                    </div>
                    <div class="info-block">
                        <h2 class="goods-name line-overflow">{{ item.data.name }}</h2>
                        <p class="code">{{ item.data.code }}</p>
                        <p class="price">¥&nbsp;{{ item.data.price }}</p>
                        <span v-if="item.data.tagStr" class="label line-overflow">{{ item.data.tagStr }}</span>
                    </div>
                    <div class="mask pointer"></div>
                    <Radio class="radio" :label="item.rowid" @click.native="handleCommodity(item)"><span>&nbsp;</span></Radio>
                </div>
                <Spin fix size="large" v-if="loading"></Spin>
            </RadioGroup>
        </div>
        <Page
            v-show="list.length > 0"
            class="page"
            :total="total"
            :current="current"
            :page-size="pageSize"
            show-elevator
            @on-change="nextPage" />
        <div slot="footer">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal> 
</template>
<script>
    import noData from '@/views/components/noData'

    export default {
        name: "",
        mixins: [],
        components: {
            noData
        },
        props: {
            show: {
                default: false
            },
            bucket_token: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                list: [],
                query: '',
                loading: false,
                total: 0,
                current: 1,
                pageSize: 40,
                commodity: {
                    rowid: null
                }
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.getData()
                }
            }
        },
        methods: {
            handleStop(e) {
                e.stopPropagation()
            },
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.query = ''
                this.list = []
                this.current = 1
                this.commodity = {
                    rowid: null
                }
                this.$emit('cancel')
            },
            submit() {
                if (this.commodity.rowid) {
                    this.$emit('confirm', this.commodity)
                }
                this.cancel()
            },
            searchNew() {
                if (this.loading) return
                this.getData()
            },
            handleCommodity(item) {
                this.commodity = item
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    bucket_token: this.bucket_token,
                    query: this.query,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize
                }
                this.loading =true
                this.$api.get('commodities/search', { params }).then(({ data=[], total_count=0, page=1 }) => {
                    if (page === this.current) {
                        data.forEach((item) => {
                            let str = ''
                            if (item.data.tag && item.data.tag.length) {
                                for (let i=0, len=item.data.tag.length; i<len; i++ ) {
                                    str += item.data.tag[i].name
                                    if (i !=  len - 1) {
                                        str += ','
                                    }
                                }
                            }
                            if (item.data.price !== null) item.data.price = typeof item.data.price==='number'?item.data.price.toFixed(2):item.data.price
                            this.$set(item.data, 'tagStr', str)
                        })
                        this.list = data
                        this.total = total_count
                        this.loading = false
                    }
                }).catch(() => {
                    this.loading = false
                })
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import '~less/color';
@deep: ~'>>>';
.commodity-block {
    width: calc(100% + 16px);
    .radio-wrap {
        position: relative;
        display: inline-block;
        margin: 0 16px 31px 0;
        padding-left: 0.7%;
        border: 1px solid #d3d3d3;
        width: calc(25% - 16px);
        padding-top: 15.2%;
        background: #f0f3f4;
        &:hover {
            .mask {
                opacity: 1;
            }
            .checkbox, .more {
                display: inline-block;
            }
        }
        .mask {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.08);
            transition: opacity .3s ease;
        }
        .radio {
            position: absolute;
            top: 0;
            right: 0;
            margin-right: 0;
            width: 100%;
            height: 100%;
        }
        .img-wrap-block {
            position: absolute;
            left: 4.6%;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            width: 52%;
            padding-top: 52%;
            background: #fff;
            border-radius: 5px;
            .img-wrap {
                width: 100%;
                padding-top: 57.4%;
                .device-status {
                    position: absolute;
                    left: 5%;
                    bottom: 10%;
                    width: 47px;
                    height: 18px;
                    padding: 0 5px;
                    border-radius: 4px;
                    background: #000;
                    color: #fff;
                    line-height: 18px;
                    .text {
                        position: absolute;
                        left: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 10px;
                    }
                }
            }
        }
        .info-block {
            position: absolute;
            top: 49%;
            left: 63.8%;
            transform: translateY(-50%);
            width: 36%;
            .goods-name {
                font-size: 14px;
                line-height: 20px;
            }
            .code {
                overflow: hidden;
                font-size: 10px;
                line-height: 16px;
            }
            .price {
                margin: 8px 0;
                font-size: 12px;
                line-height: 20px;
                color: #bc6347;
            }
            .label {
                display: inline-block;
                padding: 2px 12px;
                max-width: 80%;
                background: @primary-color;
                border-radius: 4px;
                color: #ffffff;
                text-align: center;
                font-size: 10px;
                line-height: 20px;
            }
        }
    }
}
.radio @{deep} .ivu-radio {
    position: absolute;
    right: 8px;
    top: 8px;
}
</style>