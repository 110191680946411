<template>
    <div class="tmpl-block-wrap">
        <div class="search-bar">
            <Dropdown
                :visible="visible"
                class="dropdown"
                trigger="custom"
                placement="bottom-start"
                transfer-class-name="jofee-label-dropdown"
                @on-clickoutside="handleVisible">
                <a href="javascript:void(0)" @click="handleVisible">
                    <Button class="label-more" type="default" size="large">
                        <i class="iconfont icon-gengduo"></i>
                    </Button>
                </a>
                <DropdownMenu slot="list">
                    <div class="category-label_parent">
                        <div :class="['category-label_parent_item pointer line-overflow', labelParent === 'all' ? 'active': '']" data-name="all" @click="handleClickAll">{{ $t('common.all') }}</div>
                        <div
                            :class="['category-label_parent_item pointer line-overflow', labelParent === 'type' ? 'active': '']"
                            @click.stop="handleClickParent(type_list, 'type')">{{ $t('pages.template.type') }}</div>
                        <div
                            :class="['category-label_parent_item pointer line-overflow', labelParent === 'dir' ? 'active': '']"
                            @click.stop="handleClickParent(dir_list, 'dir')">{{ $t('pages.template.size') }}</div>
                        <div
                            v-for="item in labels"
                            :key="item.rowid"
                            :data-name="item.rowid"
                            :class="['category-label_parent_item pointer line-overflow', labelParent === item.rowid ? 'active': '']"
                            @click.stop="handleClickParent(item)">{{ item.name }}</div>
                    </div>
                    <div class="category-label_children">
                        <!-- <div
                            v-if="labelParent==='type'||labelParent==='dir'"
                            v-for="child in labelChildren"
                            :key="child.key"
                            :data-name="child.key"
                            :class="['category-label_children_item pointer line-overflow', labelChild === child.key ? 'active': '']"
                            @click="handleClickChild(child)">{{ child.text._ }}</div> -->
                            <!-- v-else -->
                        <div
                            v-for="child in labelChildren"
                            :key="child.rowid?child.rowid:child.key"
                            :data-name="child.rowid?child.rowid:child.key"
                            :class="['category-label_children_item pointer line-overflow', labelChild===child.rowid||labelChild===child.key?'active':'']"
                            @click="handleClickChild(child)">{{ child.name?child.name:child.text[lang]?child.text[lang]:child.text._ }}</div>
                    </div>
                </DropdownMenu>
            </Dropdown>
            <Input v-model="query" class="search" size="large" clearable :placeholder="placeholder" @keyup.native.enter="searchNew" @on-clear="searchNew" @paste.native="handleStop" @copy.native="handleStop" @cut.native="handleStop" @keydown.native="handleStop" @keyup.native="handleStop" >
                <Icon class="pointer" type="ios-search" slot="suffix" @click="searchNew" />
            </Input>
        </div>
        <load-more class="load-more-wrap" :onRefresh="handleLoadData" :disabled="isLoaded" height="calc(100% - 60px)">
            <ul class="templates-block">
                <li
                    v-for="item in list"
                    :key="`${item.rowid}`"
                    class="template-item pointer"
                    @click="handleReplaceTmpl(item)">
                    <img :src="`/cym/${item.cover_material_uri}/thumbnail`" :class="['image-center', 'template-preview-image-bkg']" >
                </li>
            </ul>
        </load-more>
    </div>  
</template>
<script>
    import loadMore from '@/views/components/loadMore'

    export default {
        name: "",
        mixins: [],
        components: {
            loadMore
        },
        props: {
            data_type: {
                default: ''
            }
        },
        data () {
            return {
                isLoaded: false,
                current: 1,
                pageSize: 12,
                total: 0,
                list: [],
                query: '',
                loading: false,
                labels: [],
                labelChildren: [],
                labelParent: 'all',
                labelChild: '',
                placeholder: this.$t('pages.template.searchTmpl'),
                visible: false,
                type_list: [],
                dir_list: []
            }
        },
        computed: {},
        methods: {
            handleStop(e) {
                e.stopPropagation()
            },
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.list = []
                this.getData()
            },
            async handleLoadData(done) {
                await this.getData()
                done()
            },
            getData() {
                let params = {
                        query: this.query,
                        state: this.state,
                        start: (this.current - 1) * this.pageSize,
                        count: this.pageSize,
                        data_type: this.data_type
                    };
                params.type_list = this.labelParent==='type'?this.labelChild:''
                params.dir_list = this.labelParent==='dir'?this.labelChild:''
                params.admin_tag_id_list = ['type', 'dir', '', 'all'].indexOf(this.labelParent)===-1?JSON.stringify([this.labelChild]):''
                this.$api.get('templates/search', { params }).then(({ data, total_count, page }) => {
                    if (page === this.current) {
                        if (data.length !== 0) {
                            this.current += 1
                        }
                        this.list.push(...data)
                        if (this.list.length == total_count) {
                            this.isLoaded = true
                        } else {
                            this.isLoaded = false
                        }
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            async handleReplaceTmpl(item) {
                let params = {
                    tmplType: 'admin',
                }
                await this.$api.get(`templates/${item.rowid}`).then(({ data }) => {
                    params.item = data
                })
                this.$emit('handleReplaceTmpl', params)
            },
            handleClickParent(item, v) {
                if (v) {
                    this.labelParent = v
                    this.labelChildren = item
                    return
                }
                this.labelParent = item.rowid
                this.labelChildren = item.children
            },
            handleClickAll() {
                if (this.labelParent === 'all') return
                this.current = 1
                this.list = []
                this.labelParent = 'all'
                this.labelChildren = []
                this.placeholder = this.$t('pages.template.searchTmpl')
                this.visible = false
                this.labelChild = ''
                this.getData()
            },
            handleClickChild(item) {
                if (this.labelChild === item.rowid || this.labelChild === item.key) return
                this.current = 1
                this.list = []
                this.labelChild = item.rowid || item.key
                this.placeholder = `${this.$t('pages.template.in')}${item.name?item.name:item.text[this.lang]?item.text[this.lang]:item.text._}${this.$t('pages.template.search2')}`
                this.visible = false
                this.getData()
            },
            async getLabelData() {
                await this.$api.get('templates/type_list').then(({ data }) => {
                    this.type_list = data
                })
                await this.$api.get('templates/dir_list').then(({ data }) => {
                    this.dir_list = data
                })
                await this.$api.get('templates/admin_tags').then(({ data }) => {
                    this.labels = data.filter(el => el.children && el.children.length)
                }).catch(() => {})
            },
            handleVisible() {
                this.visible = !this.visible
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.getLabelData()
                this.getData()
            })
        }
    }
</script>
<style scoped lang="less">
@import "~less/color";
@deep: ~'>>>';

.tmpl-block-wrap {
    padding: 10px 0 0 0;
    height: 100%;
    .search-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 16px;
        .dropdown {
            padding: 0 0;
            .label-more {
                margin-right: 7px;
                padding: 0;
                width: 39px;
            }
            @{deep} .ivu-dropdown-menu {
                min-width: 266px;
            }
            @{deep} .jofee-label-dropdown {
                padding: 0 0;
            }
            .category-label_parent {
                display: inline-block;
                padding: 5px 0;
                width: 124px;
                border-right: 1px solid #d3d3d3;
                vertical-align: top;
                background: #f8fbff;
                .category-label_parent_item {
                    padding: 0 18px;
                    height: 28px;
                    line-height: 28px;
                }
                .active {
                    background: #fff;
                    color: @primary-color;
                }
            }
            .category-label_children {
                display: inline-block;
                padding: 5px 0;
                width: 124px;
                vertical-align: top;
                .category-label_children_item {
                    padding: 0 18px;
                    height: 28px;
                    line-height: 28px;
                }
                .active {
                    background: #fff;
                    color: @primary-color;
                }
            }
        }
        .search {
            width: 204px;
        }
    }
    .load-more-wrap {
        overflow-x: hidden;
        width: 282px;
        .templates-block {
            overflow: auto;
            width: 274px;
            .template-item {
                position: relative;
                display: inline-block;
                margin: 0 10px 10px 0;
                width: 127px;
                height: 148px;
                background: #cfcfcf;
                box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
            }
        }
    }
}
</style>