<template>
    <Modal
        class="wrap"
        v-model="modal"
        title=""
        :width="1000"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <Row class="search-bar">
            <Col span="16">
                <RadioGroup class="radio-group-button" v-model="bucket" type="button" @on-change="handleSpace">
                    <Radio label="particular">{{ $t('pages.template.otherMaterials') }}</Radio>
                    <Radio v-if="is_org" label="org">{{ $t('navigation.companySpaces') }}</Radio>
                    <Radio v-else label="user">{{ $t('navigation.personSpaces') }}</Radio>
                </RadioGroup>
                <Dropdown v-if="is_org && bucket === 'org'" class="dropdown dropdown-structure" trigger="click" placement="bottom-start" @on-visible-change="handleTreeDropdown">
                    <Icon v-if="nodeList.length" class="close" type="ios-close-circle" @click="clearNodeList()" />
                    <Button type="text">
                        {{ $t('navigation.structure') }}
                        <div v-if="nodeList.length" class="tree line-overflow">
                            <span>:</span>
                            <span v-for="(item,index) in nodeList" :key="item.department_id">{{ item.title }}
                                <span v-if="index !== nodeList.length - 1">,</span>
                            </span>
                        </div>
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list">
                        <Tree :data="data" show-checkbox @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                    </DropdownMenu>
                </Dropdown>
                <Dropdown
                    v-if="bucket==='particular'"
                    :visible="visible"
                    class="dropdown dropdown-label"
                    trigger="custom"
                    placement="bottom-start"
                    transfer-class-name="jofee-label-dropdown"
                    @on-clickoutside="handleVisible">
                    <a href="javascript:void(0)" @click="handleVisible">
                        <Icon v-if="labelChild" class="close" type="ios-close-circle" @click.stop="clearLabelList" />
                        <Button type="text">
                            {{ $t('pages.label.label') }}<span v-if="labelCild">:{{ labelChildName }}</span>
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                    </a>
                    <DropdownMenu slot="list">
                        <div class="category-label_parent">
                            <div
                                v-for="item in labels"
                                :key="item.rowid"
                                :data-name="item.rowid"
                                :class="['category-label_parent_item pointer line-overflow', labelParent === item.rowid ? 'active': '']"
                                @click.stop="handleClickParent(item)">{{ item.name }}
                            </div>
                        </div>
                        <div class="category-label_children">
                            <div
                                v-for="child in labelChildren"
                                :key="child.rowid"
                                :data-name="child.rowid"
                                :class="['category-label_children_item pointer line-overflow', labelChild === child.rowid ? 'active': '']"
                                @click="handleClickChild(child)">{{ child.name }}</div>
                        </div>
                    </DropdownMenu>
                </Dropdown>
                <Dropdown v-else class="dropdown dropdown-label" trigger="click" placement="bottom-start" >
                    <Icon v-if="label" class="close" type="ios-close-circle" @click="clearLabelList" />
                    <Button type="text">
                        标签<span v-if="label">:{{ label.name }}</span>
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list">
                        <load-more :onRefresh="handleReachBottom" :disabled="isLabelLoaded" height="200px">
                            <Tooltip class="tooltip" :content="item.name" theme="light" placement="top-start" transfer v-for="(item, index) in labelList" :key="index" :delay="1000">
                                <DropdownItem class="label line-overflow" @click.native="handleLabelList(item)">{{ item.name }}</DropdownItem>
                            </Tooltip>
                        </load-more>
                    </DropdownMenu>
                </Dropdown>
            </Col>
            <Col span="8" class="clearfix">
                <Button v-show="bucket !== 'particular'" class="float-right" type="primary" @click="handleUpload">{{ $t('pages.space.uploadMaterials') }}</Button>
                <Input v-model="query" class="float-right margin-right" clearable :placeholder="$t('pages.space.inputNameSearch')" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" @paste.native="handleStop" @copy.native="handleStop" @cut.native="handleStop" @keydown.native="handleStop" @keyup.native="handleStop" >
                    <Icon type="ios-search pointer" slot="suffix" @click="searchNew" />
                </Input>
            </Col>
        </Row>
        <no-data v-show="list.length === 0" :text="$t('pages.space.noMaterials')"></no-data>
        <div class="content-section">
            <RadioGroup v-show="list.length > 0" v-model="audio.rowid" class="audio-block" >
                <div :class="['audio-item', item.selected ? 'target': '']" v-for="item in list" :key="item.rowid">
                    <div class="img-wrap">
                        <img class="image-center" :src="`/cym/${item.uri}/thumbnail`">
                    </div>
                    <Radio class="radio" :label="item.rowid" @click.native="handleAudio($event, item)"><span>&nbsp;</span></Radio>
                    <div class="name line-overflow">{{ item.name }}</div>
                </div>
                <Spin fix size="large" v-if="loading"></Spin>
            </RadioGroup>
        </div>
        <Page
            v-show="list.length > 0"
            class="page"
            :total="total"
            :current="current"
            :page-size="pageSize"
            show-elevator
            @on-change="nextPage" />

        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
        <upload-modal
            ref="upload"
            class="audio-upload"
            :show="uploadModalShow"
            :bucket_token="bucket_token"
            :multiple="false"
            :formats="formats"
            @confirm="handleUploadConfirm"
            @cancel="handleUploadCancel"
            @show="handleUploadModal"
        ></upload-modal>
    </Modal> 
</template>
<script>
    import uploadModal from 'views/components/upload'
    import noData from '@/views/components/noData'
    import loadMore from '@/views/components/loadMore'
    import { dropdownTree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [dropdownTree],
        components: {
            uploadModal,
            noData,
            loadMore
        },
        props: {
            show: {
                default: false
            },
            type: {
                default: ''
            },
            is_org: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                query: '',
                uploadModalShow: false,
                audio: {
                    rowid: ''
                },
                current: 1,
                pageSize: 14,
                total: 0,
                list: [],
                nodeList: [],
                treeList: [],
                data: [],
                formats: ['mp3', 'mp4', 'png', 'jpg', 'jpeg', 'mov', 'avi', 'mav', 'flac'],
                bucket: 'user',
                label: null,
                labelList: [],
                labelCurrent: 1,
                labelPageSize: 10,
                isLabelLoaded: false,
                labels: [],
                labelChildren: [],
                labelParent: 'all',
                labelChild: '',
                labelChildName: '',
                visible: false
            }
        },
        computed: {
            bucket_token() {
                return this.$store.state.default_bucket_tokens[this.bucket]
            },
            tree_data() {
                return [this.$store.state.up_tree]
            }
        },
        watch: {
            show(val) {
                if (this.is_org) {
                    this.bucket = 'org'
                }
                if (this.type === 'image') {
                    this.formats = ['jpg', 'png', 'jpeg', 'mjpeg', 'bmp']
                } else if (this.type === 'video') {
                    this.formats = ['qt', 'ts', 'vob', 'webm', 'swf', 'mj2', 'mjpeg', 'mpe', 'm3u8', 'm4v', 'm2t', 'f4v', 'dv','rm', 'rmvb', '3gp', 'avi', 'mpeg', 'mpg', 'mkv', 'dat', 'asf', 'wmv', 'flv', 'mov', 'mp4', 'ogg']
                }
                this.getLabelList()
                this.modal = val
                if (val) {
                    this.data = this.handleRecursive(this.tree_data)
                    this.getData()
                }

                this.audio = {
                    rowid: ''
                }
            }
        },
        methods: {
            handleStop(e) {
                e.stopPropagation()
            },
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.bucket = 'user'
                this.query = ''
                this.current = 1
                this.list = []
                this.total = 0
                this.audio = {
                    rowid: ''
                }
                this.data = []
                this.treeList = []
                this.nodeList = []
                this.label = ''
                this.labelParent = ''
                this.labelChild = ''
                this.labelChildName = ''
                this.labelList = []
                this.labelCurrent = 1
                this.$emit('cancel')
            },
            submit() {
                if (!this.audio.rowid) {
                    return this.$Message.error(this.$t('pages.space.select'))
                }
                this.$emit('confirm', this.audio)
                this.cancel()
            },
            handleSpace() {
                this.clearLabelList()
                this.labelList = []
                this.getLabelList()
            },
            handleClickParent(item) {
                this.labelParent = item.rowid
                this.labelChildren = item.children
            },
            handleClickChild(item) {
                if (this.labelChild === item.rowid) return
                this.current = 1
                this.list = []
                this.labelChild = item.rowid
                this.labelChildName = item.name
                this.visible = false
                this.getData()
            },
            handleVisible() {
                this.visible = !this.visible
            },
            getLabelList() {
                if (this.bucket !== 'user' && this.bucket !== 'org') {
                    this.$api.get('open_materials/tags').then(({ data }) => {
                        this.labels = data.filter((item) => {
                            if (item.children && item.children.length) return item
                        })
                    }).catch(() => {})
                    return
                }
                let params = {
                    start: (this.labelCurrent - 1) * this.labelPageSize,
                    count: this.labelPageSize
                }
                params.bucket_token = this.bucket_token
                return this.$api.get('labels/list', { params }).then(({ data, total_count }) => {
                    if (data.length !== 0) {
                        this.labelCurrent += 1
                        this.labelList.push(...data)
                        if (this.labelList.length === total_count) {
                            this.isLabelLoaded = true
                        } else {
                            this.isLabelLoaded = false
                        }
                    }
                })
            },
            async handleReachBottom(done) {
                await this.getLabelList()
                done()
            },
            handleLabelList(label) {
                this.current = 1
                this.label = label
                this.list = []
                this.getData()
            },
            clearLabelList() {
                this.current = 1
                this.labelCurrent = 1
                this.label = ''
                this.list = []
                this.labelParent = ''
                this.labelChild = ''
                this.labelChildName = ''
                this.getData()
            },
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.getData()
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                        query: this.query,
                        type_list: "",
                        start: (this.current - 1) * this.pageSize,
                        count: this.pageSize
                    },
                    len = this.nodeList.length,
                    store = JSON.parse(JSON.stringify(this.audio)),
                    url = 'materials/search';
                if (this.label) {
                    params.label_id = this.label.rowid
                }
                if (this.is_org && this.bucket === 'org') {
                    if (this.type === 'image') {
                        params.type_list = 'image'
                    } else if (this.type === 'video') {
                        params.type_list = 'video'
                    }
                    if (len) {
                        let bucket_token_list = []
                        this.nodeList.map((item) => {
                            bucket_token_list.push(item.bucket_token)
                        })
                        params.bucket_token_list = JSON.stringify(bucket_token_list)
                    } else {
                        this.treeList = []
                        this.getTreeList(this.data, 'bucket_token')
                        params.bucket_token_list = JSON.stringify(this.treeList)
                    }
                } else {
                    if (this.bucket === 'particular') {
                        url = 'open_materials/search'
                        if (this.labelChild) {
                            params.tag_id = this.labelChild
                        }
                        if (this.type === 'image') {
                            params.material_type = 'image'
                        } else if (this.type === 'video') {
                            params.material_type = 'video'
                        }
                    } else {
                        if (this.type === 'image') {
                            params.type_list = 'image'
                        } else if (this.type === 'video') {
                            params.type_list = 'video'
                        }
                        params.bucket_token = this.$store.state.default_bucket_tokens.user
                    }
                }
                this.loading = true
                this.$api.get(url, { params }).then(({ data, total_count, page })=>{
                    if (page === this.current) {
                        this.list = data.map((item) => {
                            if (store.rowid === item.rowid) {
                                item.selected = true
                            } else {
                                item.selected = false
                            }
                            return item
                        })
                        this.total = total_count
                        this.loading = false
                    }
                }).catch(()=>{
                    this.loading = false
                }).finally(()=>{
                    this.audio = store
                })
            },
            handleUpload() {
                if (this.$refs['upload'].defaultList.length > 0) {
                    this.$refs['upload'].loadingModalShow = true
                    return
                }
                this.$el.querySelector('.audio-upload').getElementsByTagName('input')[0].click()
                this.$refs['upload'].init = true

            },
            handleUploadModal() {
                this.uploadModalShow = true
            },
            handleUploadConfirm() {
                this.handleUploadCancel()
                this.current = 1
                this.getData()
            },
            handleUploadCancel() {
                this.uploadModalShow = false
            },
            handleAudio(e, item) {
                let { rowid, uri, material_info } = item;
                this.audio.url = uri
                this.audio.rowid = rowid
                this.audio.resolution = material_info.resolution
                this.audio.type = material_info.media_type
                if (material_info.media_type === 'video') {
                    this.audio.volume = 1
                }
                let len = this.list.length;
                for(let i = 0; i < len; i++) {
                    if (this.audio.rowid === this.list[i].rowid) {
                        this.list[i].selected = true
                    } else {
                        this.list[i].selected = false
                    }
                }
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import '~less/color';
@deep: ~'>>>';
.search-bar {
    margin: 30px 0 20px;
    .radio-group-button {
        margin-left: -15px;
    }
    .dropdown {
        position: relative;
        vertical-align: bottom;
        .close {
            display: none;
        }
        .label-mag {
            cursor: pointer;
            padding: 7px 0 7px 4px;
            font-size: 14px;
        }
        &:hover {
            .close {
                display: block;
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }
        .tree {
            display: inline-block;
            max-width: 100px;
            vertical-align: bottom;
        }
    }
    .dropdown {
        @{deep} .ivu-dropdown-menu {
            min-width: 266px;
        }
        @{deep} .jofee-label-dropdown {
            padding: 0 0;
        }
    }
    .dropdown-label {
        .tooltip {
            display: block;
            width: 100%;
            .label {
                display: block;
                max-width: 179px;
            }
        }
        .category-label_parent {
            display: inline-block;
            padding: 5px 0;
            width: 124px;
            border-right: 1px solid #d3d3d3;
            vertical-align: top;
            background: #f8fbff;
            .category-label_parent_item {
                padding: 0 18px;
                height: 28px;
                line-height: 28px;
            }
            .active {
                background: #fff;
                color: @primary-color;
            }
        }
        .category-label_children {
            display: inline-block;
            padding: 5px 0;
            width: 124px;
            vertical-align: top;
            .category-label_children_item {
                padding: 0 18px;
                height: 28px;
                line-height: 28px;
            }
            .active {
                background: #fff;
                color: @primary-color;
            }
        }
    }
}
.content-section {
    overflow-x: hidden;
    margin: 0 auto;
    width: 968px;
    .audio-block {
        width: 980px;
        .audio-item {
            position: relative;
            display: inline-block;
            margin: 0 12px 10px 0;
            width: 128px;
            .radio {
                position: absolute;
                top: 0;
                right: 0;
                margin-right: 0;
                width: 100%;
                height: 100%;
            }
            .img-wrap {
                position: relative;
                width: 128px;
                height: 128px;
                background: #7f7f7f;
                box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-height: 100%;
                    max-width: 100%;
                }
            }
        }
        .target {
            .img-wrap {
                border: 1px solid @primary-color;
            }
        }
    }
}
@deep: ~">>>";
.radio @{deep} .ivu-radio {
    position: absolute;
    right: 8px;
    top: 8px;
}
</style>